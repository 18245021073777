import React from "react"
import { Link } from "gatsby"

import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Audits from "../components/audits"
import Buttons from "../components/buttons"

import "../scss/contactForm.scss"
import "../scss/auditMenu.scss"
import dmImage from "../images/dm-menu-image.jpg"
import dmIcon from "../images/dm-icon.svg"
import osImage from "../images/os-menu-image.jpg"
import osIcon from "../images/os-icon.svg"
import croImage from "../images/cro-menu-image.jpg"
import croIcon from "../images/cro-icon.svg"

class contact extends React.Component {
  render() {
    const metadescription =
      "Next&Co are an independent digital and traditional performance marketing agency, visit our site or call 1300 191 950"
    const metatitle =
      "Independent Australian Performance Marketing Agency - Next&Co"
    const metaurl = typeof window !== "undefined" ? window.location.href : ""

    return (
      <>
        <Helmet>
          <title>{metatitle}</title>
          <meta name="description" content={metadescription} />
          <meta name="title" content={metatitle} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={metaurl} />
          <meta property="og:title" content={metatitle} />
          <meta property="og:description" content={metadescription} />
          <meta property="og:image" content="facebook.png" />
          <meta property="twitter:url" content={metaurl} />
          <meta property="twitter:title" content={metatitle} />
          <meta property="twitter:description" content={metadescription} />
          <meta property="twitter:image" content="twitter.png" />
        </Helmet>
        <Layout>
          <section id="contact-form-container">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="col">
                    <div className="form-title">
                      <h2>Get in touch</h2>
                    </div>
                    <form
                      name="contact"
                      method="post"
                      action="/thankyou"
                      data-netlify="true"
                      data-netlify-honeypot="bot-field"
                      onSubmit={this.handleSubmit}
                    >
                      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                      <input type="hidden" name="form-name" value="contact" />
                      <p hidden>
                        <label>
                          Don’t fill this out:{" "}
                          <input
                            name="bot-field"
                            onChange={this.handleChange}
                          />
                        </label>
                      </p>
                      <p className="field">
                        <input
                          type="text"
                          required
                          name="name"
                          id="name"
                          placeholder=""
                          onChange={this.handleChange}
                        />
                        <label>Name</label>
                      </p>
                      <p className="field">
                        <input
                          type="email"
                          required
                          name="email"
                          id="email"
                          placeholder=""
                          onChange={this.handleChange}
                        />
                        <label>Email</label>
                      </p>
                      <p className="field">
                        <input
                          type="phone"
                          required
                          name="phone"
                          id="phone"
                          placeholder=""
                          onChange={this.handleChange}
                        />
                        <label>Phone</label>
                      </p>
                      <p className="field">
                        <textarea
                          name="message"
                          id="message"
                          placeholder=""
                          required
                          onChange={this.handleChange}
                        />
                        <label>Message</label>
                      </p>
                      <p>
                        <button type="submit">Send</button>
                      </p>
                    </form>
                  </div>
                  <div className="col">
                    <h4 className="title">Our office</h4>
                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="address"
                        href="https://www.google.com/maps/place/11+Queens+Rd,+Melbourne+VIC+3004/@-37.8385106,144.9716223,17z/data=!3m1!4b1!4m6!3m5!1s0x6ad6681cf3caef09:0x16ef90dd933b251a!8m2!3d-37.8385106!4d144.9741972!16s%2Fg%2F11t31s35bn?entry=ttu"
                      >
                        Level 11, 11 Queens Rd Melbourne, VIC 3004
                      </a>
                    </p>
                    <p>
                      <a className="telephone" href="tel:1300191950">
                        1300 191 950
                      </a>
                    </p>
                    <p>
                      <a
                        className="email"
                        href="mailto:enquiries@nextandco.com.au?subject=Next and Co Enquiry"
                      >
                        enquiries@nextandco.com.au
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    )
  }
}

export default contact
